<template>
  <div class="qualifications">

<!--    <section class="search-option">-->
<!--      <span class="search_title active">证书编号</span>-->
<!--      <el-input placeholder="证书编号" v-model="qualificationsObject.certificateNumber" class="_one"/>-->

<!--      <span style="margin-left: 20px" class="search_title">资质类型</span>-->
<!--      <el-input placeholder="资质类型" v-model="qualificationsObject.certificateType" class="_one"/>-->

<!--      <span style="margin-left: 20px" class="search_title">状态证书</span>-->
<!--      <el-select class="_one" v-model="qualificationsObject.certificateStatus" placeholder="请选择">-->
<!--        <el-option-->
<!--          v-for="item in options"-->
<!--          :key="item.value"-->
<!--          :label="item.label"-->
<!--          :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->

<!--      <span style="margin-left: 20px" class="search_title">发证时间</span>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="qualificationsObject.issueBeginDate" type="date" placeholder="选择时间"/>-->
<!--      <i style="margin-right: 5px" class="el-icon-minus"/>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="qualificationsObject.issueEndDate" type="date" placeholder="选择时间"/>-->

<!--      <span style="margin-left: 20px" class="search_title">截止时间</span>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="qualificationsObject.validBeginDate" type="date" placeholder="选择时间"/>-->
<!--      <i style="margin-right: 5px" class="el-icon-minus"/>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="qualificationsObject.validEndDate" type="date" placeholder="选择时间"/>-->

<!--      <div class="function">-->
<!--        <el-button style="width: 85px;border-radius: 10px;text-align: center" @click="resetCondtion">清空</el-button>-->
<!--        <el-button type="primary" style="margin-right: 59px;width: 85px;border-radius: 10px;text-align: center;margin-left: 20px" @click="current = 1;getList()">筛选</el-button>-->
<!--      </div>-->

<!--    </section>-->

    <div class="monitoring_table">
      <vxe-grid
        stripe
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="770px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :cell-class-name="cellClassName"
        :row-style="rowStyle"
        v-bind="gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >

        <!--将表单放在工具栏中-->
        <template #toolbar_buttons>
          <div class="monitoring_two">
            <div class="monitoring_two_btn">
              <div class="search_input">
                <el-input  size="small"  v-model="qualificationsObject.keyword" placeholder="请输入关键词搜索..."></el-input>
                <i @click="current = 1;getList()" style="color: #2D3748;font-size: 18px;margin-left: 5px" class="el-icon-search"></i>
              </div>
              <div class="function_btn">
                <div class="btn_1" @click="add()">
                  <img src="../../assets/images/hostingPLatform/add.png" alt="">
                  添加监控
                </div>
                <div class="btn_2" @click="cancelProxy()">
                  取消监控
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #imageUrl="{ row }">
          <img style="height: 100%; max-width: 100%" :src="returnUrl(row.image)" v-if="row.isImage == '图片'" alt=""/>

          <span style="display: flex; align-items: center" v-else>{{row.tmcn}}</span>
        </template>

        <template #term="{ row }">
          <div v-if="row.endDate!=''&&row.startDate!=''">{{row.startDate}} 至 {{row.endDate}}</div>
        </template>

        <template #monitorType="{ row }">
          <div>{{row.monitorType == 1 ? '近似监控' : '竞争对手监控'}}</div>
        </template>

        <template #operation="{ row }">
          <div class="operation" >
            <div v-if="row.certificateUrl" @click="viewCertificate(row)">
              查看证书
            </div>
            <el-upload v-else
                       :headers="headers"
                       action="/admin/sys-file/upload"
                       :auto-upload="true"
                       :show-file-list="false"
                       :before-upload="beforeAvatarUpload"
                       :on-success="uploadFn"
            >
              <div  @click="uploadCertificate(row)">
                上传证书
              </div>
            </el-upload>

          </div>
        </template>

        <template #pager>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20, 50, 100]"
            layout="total, sizes, prev, pager, next"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total">
          </el-pagination>
        </template>

        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>

    <template>
      <el-dialog width="720px" :visible.sync="monitor" class="softness_dialog">
        <div style="display: flex;align-items: center" slot="title">
          <img src="../../assets/images/hostingPLatform/tips.png" alt="">
          <span style="margin-left: 11px;font-size: 16px;font-weight: 500;color: #444444;">请选择您要添加的数据</span>
        </div>
        <el-select @change="selectApplican" v-model="applicantName" placeholder="请选择">
          <el-option
            v-for="item in applicantNameList"
            :key="item.applicantName"
            :label="item.applicantName"
            :value="item.applicantName">
          </el-option>
        </el-select>
        <div class="monitor_clearfix">
          <span class="table_title">资质</span>
          <el-table
            height="300"
            :row-class-name="noEnable"
            :header-cell-style="{background: '#D3DBF8'}"
            style="width: 622px"
            :row-key="getRowKeys"
            ref="certificatePlatformPage"
            :data="summaryData.certificatePlatformPage && summaryData.certificatePlatformPage.records"
            tooltip-effect="dark"
            @selection-change="(val) => handleSelectionChange(val)">
            <el-table-column
              :selectable="selectable"
              :reserve-selection="true"
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="证书编号"
            >
              <template slot-scope="scope">{{ scope.row.certificateNumber }}</template>
            </el-table-column>
            <el-table-column
              prop="certificateType"
              label="资质类型"
            >
            </el-table-column>
            <el-table-column
              prop="certificateStatus"
              label="证书状态"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="certificateIssueDate"
              label="发证时间"
              show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 10px"
            background
            @current-change="handleCurrentChanges"
            :current-page.sync="tableConfig.current"
            :page-size="tableConfig.size"
            layout="total, prev, pager, next"
            :total="summaryData.certificatePlatformPage && summaryData.certificatePlatformPage.total">
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
           <el-button  @click="save">保存</el-button>
        </span>
      </el-dialog>

      <!--尼斯分类-->
      <el-dialog :visible.sync="niceVisible" class="long-dialog-old" style="margin-top: 23px">
        <div class="long-inner" style="width: 741px; height: 351px;">
          <classify @closeFn="close" @classifyConfirm="classifyType" class="high-classify" style="height: 532px; width: 741px"/>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="login_dialog" :before-close="closeEvent">
        <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"/>
      </el-dialog>
      <el-dialog custom-class="_softwareUrl" title="查看证书" :visible.sync="dialogsoftwareUrlVisible">
        <img style="width: 500px" :src="this.certificateUrl" alt="">
      </el-dialog>
    </template>
  </div>
</template>

<script>
  import classify from "@/components/query/public/classInTheNiceClassification";
  import login from "@/components/common/login"
  import moment from 'moment'
  import {customStart} from "@/request/trademarkPayment";

  export default {
    components: { classify, login },

    data() {
      return {
        applicantNameList: [],
        custodyTypeSet: 'certificate',
        summaryData:[],
        multipleSelection:[],
        applicantName: '',
        qualificationsObject:{
          keyword:'',
          //证书编号
          certificateNumber: '',
          //资质类型
          certificateType: '',
          //资质状态
          certificateStatus: '',
          orderField: '',
          orderType: '',
          //发证开始日期
          issueBeginDate: '',
          //发证结束日期
          issueEndDate: '',
          //截止开始日期
          validBeginDate: '',
          //截止结束日期
          validEndDate: ''
        },
        options: [{
          value: '有效',
          label: '有效'
        }, {
          value: '含过渡期',
          label: '含过渡期'
        }, {
          value: '未知',
          label: '未知'
        }, {
          value: '撤销',
          label: '撤销'
        }],
        monitorType:[
          {
            label: '近似监控',
            value: 1
          },
          {
            label: '竞争对手监控',
            value: 2
          }
        ],
        //监控商标
        monitoringTrademark: "",
        //选择时间
        start_date_1: '',
        start_date_2: '',
        //结束日期
        end_date_1: '',
        end_date_2: '',
        //分页信息
        current: 1,
        size: 20,
        total: 0,
        //表格过滤使用
        isImage: '',
        appnos: '',
        countryNames: '',
        intCls: '',
        cnApplicant: '',

        //表格展示配置
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              type: 'checkbox',
              width: '50', fixed: 'left'
            },
            {
              align: 'left',
              field: 'certificateNumber',
              title: '证书编号',
              width: '160',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'certificateType',
              title: '资质类型',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'certificateStatus',
              title: '证书状态',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              align: 'left',
              field: 'certificateIssueDate',
              title: '发证时间',
              sortable: true,
              sortType: 'string'
            },
            {
              align: 'left',
              field: 'certificateValidDate',
              title: '截止时间',
              sortable: true,
              sortType: 'string'
            },
            {
              align: 'left',
              field: 'showName',
              title: '操作',
              fixed: "right",
              slots: {
                default: 'operation'
              }
            }
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //总次数
        totalTimes: '',
        //使用次数
        usageTimes: '',
        //剩余次数
        remainTimes: '',

        //新增监控dialog
        monitor: false,
        //新增监控或修改监控 0-新增 1-修改
        addOrEdit:0,
        addOrEditData:{
          id:"",
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourctType:0,
          revokeThreeFlag:undefined,
          renewalFlag:undefined,
          trademarkApplyNo:undefined,
          trademarkOwnFlag:undefined,
          renewalNiceCode:undefined,
          applyPersonName:undefined
        },
        containCN:false,
        beforeEditCountryListNum:0,
        //申请人联想
        applyPersonNameArr:[],

        //上传图片header
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },

        //是否禁止新增监控(防止多次提交使用)
        isDisabled: false,

        //类别选择浮窗
        niceVisible: false,
        //商标监控种类选择-0 商标续展种类选择-1
        monitorCategoryOrRenewalCategory:0,

        //登录浮窗
        dialogVisibles: false,
        dialogClose: false,
        //获取选中值
        valueArr: [],
        dialogsoftwareUrlVisible: false,
        tableConfig:{
          size: 20,
          current: 1,
        },
        certificateNumber: '',
        //查看证书
        certificateUrl:'',
      }
    },

    async mounted() {
      // this.applicantName = localStorage.getItem('applicant')
      //数据初始化
      await this.getList()
      this.editeApplicantName()
    },

    watch: {
      monitor(newVal, oldVal){
        if(newVal){
          this.multipleSelection = []
          this.$refs.certificatePlatformPage.clearSelection();
        }
      },
      // 'addOrEditData.applyPersonName':{
      //   handler () {
      //     console.log(123456)
      //     if(this.addOrEditData.countrySet){
      //       this.applicantHit()
      //     }else {
      //       this.$message('为了为您提供更准确地结果，请您先选择国家/地区');
      //     }
      //   }
      // },
      //新增或修改商标，监控国家过滤
      filterText(val) {
        this.$refs.tree[0].filter(val)
      },
    },

    methods: {
      selectApplican(e){
        this.summaryApplicant()
      },
      selectable(row, index) {
        if(row.existFlag){
          return false
        }else {
          return true
        }
      },
      noEnable({row, rowIndex}){
        if(row.existFlag) return 'existFlag'

      },
      //表格页码变化触发
      handleCurrentChanges(currentPage) {
        this.tableConfig.current = currentPage
        //获取列表数据
        this.summaryApplicant()
      },
      editeApplicantName(){
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicantNameList = data.data.filter(item => item.storeFlag)
            this.applicantName = this.applicantNameList[0].applicantName
          }
        })
      },
      //取消代理
      cancelProxy(){
        let data = this.$refs.xGrid.getCheckboxRecords()
        let arr = []
        if(data.length > 0){
          this.$axios
            .post("/custody/certificate/cancel", {
              certificatePlatformList: data.map(item => {return {certificateNumber:  item.certificateNumber}})
            }).then(({data}) => {
            if(data.code == 0){
              this.$message({
                message: data.data,
                type: 'success'
              });
              this.getList()
            }else {
              this.$message.error('取消代理失败');
            }
          }).catch(err => {
            this.$message.error('取消代理失败');
          })
        }else {
          this.$message.error('请选择需要取消代理的商标');
        }
      },
      selectable(row, index) {
        if(row.existFlag){
          return false
        }else {
          return true
        }
      },
      //查看证书
      viewCertificate(row){
        this.dialogsoftwareUrlVisible = true
        this.certificateUrl = row.certificateUrl
      },
      //上传证书
      uploadCertificate(row){
        this.certificateNumber = row.certificateNumber
      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      //新增监控图片上传前的钩子
      beforeAvatarUpload(file) {
        const isLt5M = file.size < 1024 * 1024 * 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          return false
        }
        return true
      },
      //新增监控上传图片成功
      uploadFn(response) {
        if (response.code == 0){
          this.$axios
            .post("/custody/certificate/setCertificateUrl",{
              certificateNumber: this.certificateNumber,
              certificateUrl: response.data.url
            }).then(({data}) => {
            this.$message.success('上传成功');
            this.getList()
            return;
          })
        }
        else this.$message('图片上传失败')
      },
      //获取商标监控列表
      getList() {
        this.$axios.post("/custody/certificate/queryCertificateList",{
          ...this.qualificationsObject,
          current: this.current,
          size: this.size
        })
          .then(({data}) => {
            if (data.records && data.records.length) {
              this.gridOptions.data = data.records
              this.total = data.total
            }
            else {
              this.gridOptions.data = []
            }
          })
          .catch( error => {console.log(error) })
      },
      //跳转
      skip(item) {this.$router.push({path: item})},
      //重置
      resetCondtion() {
        this.qualificationsObject = {
          //证书编号
          certificateNumber: '',
            //资质类型
            certificateType: '',
            //资质状态
            certificateStatus: '',
            //发证开始日期
            issueBeginDate: '',
            //发证结束日期
            issueEndDate: '',
            //截止开始日期
            validBeginDate: '',
            //截止结束日期
            validEndDate: ''
        }
        this.getList()
      },
      getRowKeys(row) {
        return row.platformTmId
      },
      save(){
        this.multipleSelection.push({
          applicantName: this.summaryData.applicantName,
          custodyTypeSet: [],
          certificatePlatformList: this.valueArr
        })
        this.multipleSelection.map(item => {
          if(item.patentPlatformList) item.custodyTypeSet.push('patent')
          if(item.trademarkRenewalPlatformList) item.custodyTypeSet.push('trademark')
          if(item.softwarePlatformList) item.custodyTypeSet.push('software')
          if(item.certificatePlatformList) item.custodyTypeSet.push('certificate')
          return item
        })

        this.multipleSelection = this.multipleSelection.filter(item => item.patentPlatformList || item.trademarkRenewalPlatformList || item.softwarePlatformList || item.certificatePlatformList)

        if(this.multipleSelection.length == 0){
          this.$message.error('请选择要保存的知识产权托管数据');
          return;
        }
        this.$axios
          .post("/custody/home/save/update/custody", this.multipleSelection[0]).then(({data}) => {
          if(data.code == 0){
            this.$message({
              message: data.data,
              type: 'success'
            });
            this.monitor = false
            this.current = 1
            this.getList()
          }else {
            this.$message.error('知识产权托管数据保存失败');
          }
        }).catch(err => {
          this.$message.error(err.data.msg);
        })
      },
      //多选的方法
      handleSelectionChange(val) {
        this.valueArr = val
      },
      //表格过滤变化触发
      filterChange() {
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        let getSortColumns = this.$refs.xGrid.getSortColumns()
        this.qualificationsObject = {
          //证书编号
          certificateNumber: '',
          //资质类型
          certificateType: '',
          //资质状态
          certificateStatus: '',
          orderField: '',
          orderType: '',
        }
        this.current = 1

        if (getSortColumns.length > 0) {
          if(getSortColumns[0].property = "certificateValidDate") {
            this.qualificationsObject.orderField = 2
          }else {
            this.qualificationsObject.orderField = 1
          }
          getSortColumns[0].order == 'desc' ? this.qualificationsObject.orderType = 1 : this.qualificationsObject.orderType = 2;
        } else {
          this.qualificationsObject.orderType = ''
          this.qualificationsObject.orderField = ''
        }
        if(getCheckedFilters.length > 0){
          getCheckedFilters.map(item => {
            switch (item.property) {
              //申请人名称
              case "certificateNumber":
                this.qualificationsObject.certificateNumber = item.datas[0];
                break;
              case "certificateType":
                this.qualificationsObject.certificateType = item.datas[0];
                break;
              case "certificateStatus":
                this.qualificationsObject.certificateStatus = item.datas[0];
                break;
            }
          })
        }else {
          this.qualificationsObject.certificateStatus = ''
          this.qualificationsObject.certificateType = '';
          this.qualificationsObject.certificateNumber = '';
        }

        this.getList()
      },
      summaryApplicant(){
        this.$axios
          .post("/custody/home/applicant/info/summary", {
            applicantNameSet: [this.applicantName],
            custodyTypeSet: [this.custodyTypeSet],
            current: this.tableConfig.current,
            size: this.tableConfig.size
          },{timeout: 60 * 1000 * 5}).then(({data}) => {
          if(data.code == 0){
            this.summaryData = data.data[0]
          }
        })
      },
      //新增商标监控
      add() {
        this.summaryApplicant()
        this.monitor = true
      },
      //给图片添加地址
      returnUrl(url) {
        if (url.startsWith('/group')) return "https://tmimage.lanternfish.cn"+url
        else return url
      },
      handleSizeChange(pageSize) {
        this.size = pageSize
        //获取列表数据
        this.getList()
      },
      //表格页码变化触发
      handleCurrentChange(currentPage) {
        this.current = currentPage
        //获取列表数据
        this.getList()
      },
      close() {
        this.niceVisible = false
        this.monitor = true
      },
      classifyType(data) {
        if (this.monitorCategoryOrRenewalCategory == 0){
          this.addOrEditData.niceSet = []
          data.forEach(item=>{
            this.addOrEditData.niceSet.push(item.no)
          })
        }
        else {
          if (data.length > 1){
            this.$message("此处仅可以选择一个类别")
            return
          }
          else {
            this.addOrEditData.renewalNiceCode = data[0].no
          }
        }
      },

      // 登录弹层隐藏
      loginDialog(data) { this.dialogVisibles = data },
      dialogCloseBool(data) { this.dialogClose = data },
      closeEvent(done) {
        this.dialogClose = true
        done()
      },
    },

    computed: {
      //所有国家
      allCountry(){ return this.$t(`PQ.cityOptions`).concat(this.$t(`PQ.city`), this.$t(`PQ.cityOptionsd`), this.$t(`PQ.cityOptionsds`), this.$t(`PQ.cityOpts`), this.$t(`PQ.cityds`), this.$t(`PQ.propertyOrganization`))},
    }
  }
</script>

<style lang="less">
  .qualifications {
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-grid--pager-wrapper {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(244,247,252,0.75);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      text-align: right;
      ul {
        background: rgba(244,247,252,0.75);
      }
      button {
        background: rgba(244,247,252,0.75);
      }
      .el-input__inner {
        background: rgba(244,247,252,0.75);
        border: none;
      }
    }

    .vxe-cell--checkbox {
      .vxe-checkbox--icon {
        font-size: 16px;
        width: 17px;
        height: 16px;
        color: #ffffff;
        background: #ffffff;
        border-radius: 4px; /* 圆角 */
        border: none!important;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) ; /* 阴影效果 */
      }
    }
    /* 自定义 VXE-Table 复选框的阴影效果 */
    .vxe-cell--checkbox.is--checked {
      .vxe-checkbox--icon {
        /*border: 1px solid #dcdfe6!important; !* 边框颜色 *!*/
        &.vxe-icon-checkbox-checked {
          width: 18px;
          height: 17px;
          box-shadow: none !important; /* 阴影效果 */
          color: #2264E5!important;
        }
      }
    }

    .vxe-body--row {
      height: 64px;
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
    }

    .vxe-table--header {
      height: 70px;
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    .color-row1-1{
      background: #F5F7FA;
    }
    tbody {
      .vxe-cell {
        font-weight: 500;
        font-size: 12px;
        color: #464F60;
        .trademarkImageUrl {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
          border-radius: 8px!important;
          padding: 8px 31px!important;
          box-sizing: border-box;
        }

        .trademarkStatus {
          width: 52px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #EAECFF;
          border-radius: 20px;
          font-weight: 400;
          font-size: 12px;
          color: #4F5AED;
        }
      }
    }

    .vxe_grid_header {
      font-weight: 500;
      font-size: 14px;
      color: #464F60;
      border-radius: 5px 5px 0px 0px !important;
      overflow: hidden !important;
      background: rgba(244,247,252,0.75) !important;
    }

    .row--checked,.row--hover {
      background: #EBF0FA !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
      .col--checkbox {
        position: relative;
        &:before {
          width: 2px;
          height: 64px;
          background: #2264E5;
          border-radius: 0px 0px 0px 0px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .trademarkStatus {
        width: 52px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff!important;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #4F5AED;
      }
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
      .operation {
        display: flex;
        justify-content: space-between;
        div {
          width: 78px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:nth-of-type(1) {
            background: #F2F6FF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #3B5789;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
          }
          &:nth-of-type(2) {
            background: #2264E5;
            border-radius: 15px 15px 15px 15px;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
    }

    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }
</style>

<style scoped lang="less">
  .el-select-dropdown__item {
    height: auto !important;
  }

  .el-tree {
    display: flex;
    flex-direction: column;
  }

  section.search-option {
    min-height: 100px;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    /*border: solid 1px #e4e4e4;*/
    margin-bottom: 20px;
    padding-top: 23px;
    padding-left: 19px;
    /*height: 135px;*/
    padding-bottom: 20px;
    .function{
      display: flex;
      justify-content: end;
    }
    .search_title {
      font-size: 14px;
      color: #474747;
      margin-right: 14px;
      &.active{
        position: relative;
        padding-left: 16px;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 17px;
          background: #465CFC;
          left: 0;
          top: 2px;
        }
      }
    }

    div {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .el-select-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;

      .el-input {
        input.el-input__inner {
          border: 1px solid #cccccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .one, .el-input {
      width: 180px;

      input {
        height: 34px !important;
        border-color: #ccc;
        color: inherit;
        padding: 0 4px;
      }
    }

    ._one /deep/ .el-input__inner {
      height: 34px;
    }

    .el-date-editor-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;
      line-height: 32px;

      input.el-input__inner {
        border: 1px solid #cccccc;
        text-indent: 42px;
      }
    }
  }

  .qualifications {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 17px;

      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }

      span {
        margin: 0 9px;

        &:nth-of-type(1) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          color: #506bce;
        }
      }

      span:hover {
        cursor: pointer;
        color: #506bce;
      }
    }

    box-sizing: border-box;

    .monitoring_top {
      height: 131px;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 15px auto;

      .condition {
        padding: 45px 0 0 45px;

        .el-input__inner {
          height: 36px;
        }

        .el-input {
          width: 220px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    .monitoring_two {
      width: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      justify-content: space-between;
      .monitoring_two_btn{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .search_input {
          display: flex;
          width: 296px;
          height: 45px;
          align-items: center;
          padding: 0 10px;
          background: #F7F7F8;
          border-radius: 15px 15px 15px 15px;
          border: 1px solid #E2E8F0;
          i {
            cursor: pointer;
          }
          /deep/input {
            background: #F7F7F8;
            border: none;
          }
        }
        .function_btn {
          display: flex;
          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .btn_1 {
            width: 112px;
            height: 32px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #30396C;
            font-weight: 400;
            font-size: 12px;
            color: #30396C;
            margin-right: 19px;
            img {
              margin-right: 3px;
            }
          }

          .btn_2 {
            width: 86px;
            height: 32px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #2555A2;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
            margin-right: 19px;
          }

          .btn_3 {
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            width: 115px;
            height: 32px;
            background: #2264E5;
            border-radius: 8px;
            img {
              margin-right: 3px;
            }
          }
        }
      }
      .totalMonitoring {
        background: #f4f7ff;
        font-size: 14px;
        color: #383838;
        width: 354px;
        height: 44px;
        border-radius: 20px;
        margin-right: 32px;
        display: flex;
        align-items: center;
        padding: 0 37px 0 33px;
        justify-content: space-between;
        box-sizing: border-box;

        i {
          font-size: 16px;
        }

        .active1 {
          color: #3057e3;
        }

        .active2 {
          color: #ff8d1a;
        }
      }

      .jg {
        width: 14px;
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        margin-right: 14px;
      }

      .recommend-tips {
        vertical-align: bottom;
        width: 425px;
        padding: 7px 12px;
        line-height: 17px;
        background: #fbedec;
        border: 1px solid #f6d4d4;
        font-size: 12px;
        text-align: left;

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          border-bottom: 1px solid #f6d4d4;
          border-left: 1px solid #f6d4d4;
          top: 50%;
          left: -7px;
          transform: translateY(-50%) rotate(45deg);
          background: #fbedec;
        }
      }

      .addMsg {
        position: absolute;
        left: 110px;
        top: -18px;
      }

      .add {
        width: 152px;
        height: 32px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        color: #2c3e50;
        cursor: pointer;
        margin-top: 30px;
      }

      .jum {
        width: 120px;
        margin-left: 15px;
      }

      .add:hover {
        background: #193dbe;
        color: #fff;
        border: 1px solid #193dbe;
      }

      .batchImport {
        float: right;
        margin-top: 35px;

        span {
          margin-right: 15px;
          vertical-align: middle;

          img {
            vertical-align: middle;
          }

          .upload-demo {
            .el-upload-dragger {
              width: 100px;
              height: auto;
              background: none;
              border: none;
              margin-top: 3px;
            }

            .el-upload-list {
              display: none;
            }
          }
        }

        .icondaoru-tianchong {
          font-weight: bold;
          vertical-align: middle;
          color: #de5455;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }

    .monitoring_table {
      padding: 13px 19px;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
      border-radius: 15px 15px 15px 15px;
      /deep/.el-button--primary.is-plain {
        color: #ffffff;
      }
    }


    .seeResults:hover {
      text-decoration: underline;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .monitor {

      .prompt {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e4e4e4;
        margin: 0 32px;
        text-align: center;

        .text {
          font-size: 14px;
          color: #222222;
          vertical-align: middle;
          margin-left: 10px;
        }
      }

      .mainBody {
        width: 495px;
        margin: 0px auto 0px;

        .check {
          margin-bottom: 20px;

          .requiredStar {
            color: #d01027;
            font-weight: bold;
            font-size: 16px;
          }

          .title:nth-child(1) {
            width: 105px;
            font-size: 14px;
            color: #222222;
            text-align: left;
            vertical-align: middle;
          }

          .title {
            display: inline-block;
          }

          .right {
            width: 380px;
            text-align: left;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .upload-drag {
              height: 82px !important;

              &.uploadDisabled {
                position: relative;

                .el-upload {
                  .el-upload-dragger {
                    .el-upload-disabled-shade {
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      right: 0;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            .el-upload-dragger {
              border: 1px solid #e4e4e4;
              height: 82px;
              width: 380px;
              cursor: pointer;
            }

            .upload-img {
              height: 96%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            p.delete {
              position: absolute;
              z-index: 5;
              right: 10px;
            }

            p {
              margin-top: 12px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #999999;

              &.delete:hover {
                color: #0b6ce8;
              }
            }

            .leftText {
              height: 36px;
              border-color: #e4e4e4;
            }

            .el-input-long-officialt {
              width: 380px;
              background-color: #fff;

              &.active {
                width: 360px;
              }
            }

            .rightText {
              float: right;
              border-left: 1px solid #ccc;
              width: 40px;
              text-align: center;
            }

            .el-select-long-officialt {
              width: 380px;
              background-color: #fff;

              .el-input__suffix-inner {
                margin-top: 9px;
              }
            }

            .result {
              margin: 10px 0 0;

              .resultImg {
                width: 82px;
                height: 82px;
                border: 1px solid #e4e4e4;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                float: left;
              }

              .resultText {
                float: left;
                margin-left: 16px;

                span {
                  color: #222;
                  font-size: 14px;
                }
              }
            }
          }

          .niceCode {
            width: 380px;
            border: 1px solid #e4e4e4;
            height: 38px;
            border-radius: 3px;

            .leftText {
              border: transparent;
              width: 310px;

              input.el-input__inner {
                border: none !important;
              }
            }

            .rightText {
              width: 60px;
              height: 25px;
              margin-top: 9px;
            }
          }

          .el-select {
            .el-select__tags {
              .el-select__input {
                border: none !important;
              }
            }
          }
        }
      }

      .dialog-footer {
        float: right;
        margin-right: 113px;

        .submit {
          width: 90px;
          height: 36px;
          display: inline-block;
          background-color: #d01027;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          margin-top: 22px;
          margin-left: 32px;
          cursor: pointer;
          border-radius: 3px;
        }

        .submit:hover {
          background-color: #d73e3f;
        }
      }
    }
  }
  ._softwareUrl{
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
  }

  .softness_dialog{
    box-sizing: border-box;
    /deep/.el-dialog__body{
      padding: 0 22px;
      box-sizing: border-box;
    }
    /deep/.el-dialog{
      border-radius: 8px;
    }
    .monitor_title{
      font-size: 16px;
      font-weight: 500;
      color: #2E54D9;
      padding: 14px 0 0px 34px;
    }
    .monitor_clearfix{
      margin-top: 19px;
      width: 676px;
      height: 440px;
      background: #EFF2FD;
      border-radius: 12px;
      border: 1px solid #E1E7F2;
      padding: 16px 0 0 20px;
      box-sizing: border-box;
      .table_title{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        position: relative;
        padding-left: 7px;
        &::before{
          position: absolute;
          width: 3px;
          height: 18px;
          background: #465CFC;
          content: '';
          left: 0;
          top: 4px;
        }
      }
      .el-table{
        margin-top: 14px;
        border-radius: 6px 6px 0px 0px;
        /deep/.existFlag td{
          background: #F5F7FA;
        }
      }
    }
  }
</style>
